import { Dispatch, FC, SetStateAction } from 'react';

import { isEmpty } from 'lodash';

import { Add, AngleDown, AngleForward } from '@assets/icons';
import { Button, LockedButton, PermissionAccess } from '@components/index';
import { UserPermissions } from '@enums/user-permissions';
import { Actions, AssetType } from '@pages/home-page/enums';
import { GroupedKnowledgeItems, SelectedAsset } from '@pages/home-page/types';
import { getFilteredKnowledgeItems } from '@pages/home-page/utils';

import AttributeFilter, { FilterData } from './AttributeFilter';
import { SavedFilter } from './CollapsibleAssetItem';

interface CollapsibleAssetWrapperProps {
  isCollapsed: boolean;
  setIsCollapsed: Dispatch<SetStateAction<boolean>>;
  savedFilter: SavedFilter;
  setSavedFilters: Dispatch<SetStateAction<SavedFilter>>;
  header: string;
  assetType: AssetType;
  setSelectedAction: Dispatch<SetStateAction<SelectedAsset | null>>;
  knowledgeDataItems?: GroupedKnowledgeItems;
  setFilteredKnowledgeItems: Dispatch<
    SetStateAction<GroupedKnowledgeItems | null>
  >;
}

export const CollapsibleAssetWrapper: FC<CollapsibleAssetWrapperProps> = ({
  isCollapsed,
  savedFilter,
  setSavedFilters,
  setIsCollapsed,
  header,
  assetType,
  setSelectedAction,
  knowledgeDataItems,
  setFilteredKnowledgeItems,
}) => {
  const handleCollapse = () => {
    setIsCollapsed(prev => !prev);
  };

  const getUniqueSourceDocuments = (
    knowledgeDataItems?: GroupedKnowledgeItems,
  ): FilterData[] => {
    const uniqueSourceDocs = new Set<number>();
    const availabelSourceDocuments: FilterData[] = [];

    if (knowledgeDataItems) {
      for (const dataItems of Object.values(knowledgeDataItems)) {
        for (const item of dataItems) {
          const sourceDocument = item.sourceDocument;
          if (sourceDocument?.id && !uniqueSourceDocs.has(sourceDocument.id)) {
            uniqueSourceDocs.add(sourceDocument.id);
            availabelSourceDocuments.push({
              id: sourceDocument.id,
              label: sourceDocument.label,
            });
          }
        }
      }
    }

    return availabelSourceDocuments;
  };

  const convertObjectToArray = (obj: GroupedKnowledgeItems) =>
    Object.keys(obj).map(key => ({ id: key, label: key }));

  const applyFilters = (filters: {
    category: FilterData[];
    document: FilterData[];
  }) => {
    const filteredItems = getFilteredKnowledgeItems(
      knowledgeDataItems as GroupedKnowledgeItems,
      filters,
    );
    setFilteredKnowledgeItems(filteredItems);
  };

  const onClearFilter = () => setFilteredKnowledgeItems(null);

  return (
    <tr className="w-full relative">
      <td
        colSpan={4}
        className={`h-[calc((100vh-325px)/8)] ${assetType === AssetType.RAW_FILE ? '' : 'border-t'}`}
      >
        <div className="mb-0.5">
          <div className="w-full flex justify-between px-4">
            <button
              className="flex space-x-2 h-14 items-center w-full"
              onClick={handleCollapse}
            >
              <div className="flex space-x-3 items-center">
                {isCollapsed ? (
                  <AngleForward className="w-4 h-4" />
                ) : (
                  <AngleDown className="w-4 h-4" />
                )}
                <div className="text-sm">{header}</div>
              </div>
            </button>
            <div className="flex items-center gap-2">
              {assetType === AssetType.KNOWLEDGE &&
                !isEmpty(knowledgeDataItems) &&
                !isCollapsed && (
                  <AttributeFilter
                    savedFilter={savedFilter}
                    setSavedFilters={setSavedFilters}
                    onApplyFilter={applyFilters}
                    onClearFilter={onClearFilter}
                    documentList={getUniqueSourceDocuments(knowledgeDataItems)}
                    categoryOptions={convertObjectToArray(
                      knowledgeDataItems as GroupedKnowledgeItems,
                    )}
                  />
                )}
              <div className="flex items-center">
                <PermissionAccess
                  permission={UserPermissions.MANAGE_BRAND_LIBRARY}
                  AllowedContent={
                    <Button
                      label="Add new"
                      onClick={e => {
                        e.stopPropagation();
                        setSelectedAction({ action: Actions.ADD, assetType });
                      }}
                      IconLeftComponent={Add}
                      classOverride="border-gray-4 px-2 text-sm text-gray-3 h-8"
                    />
                  }
                  DeniedContent={
                    <LockedButton
                      buttonText="Add new"
                      buttonClassname=" px-2 text-sm text-gray-3 h-8 w-[110px]"
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};
