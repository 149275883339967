import { FC, useEffect, useMemo, useState } from 'react';

import { isNil } from 'lodash';
import { useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import {
  Button,
  Select,
  SelectTypeRefComponent,
  TextArea,
} from '@components/index';
import { MAX_ATTRIBUTE_DETAILS_CHARACTER_LENGTH } from '@pages/home-page/constants';
import { AssetType } from '@pages/home-page/enums';
import { useBrandLibrary } from '@pages/home-page/hooks/use-brand-library';
import { useUpdateBrandAsset } from '@pages/home-page/hooks/use-update-brand-asset';
import {
  AddAttributeData,
  AttributeType,
  DataItem,
} from '@pages/home-page/types';
import { convertKnowledgeOptionsToArray } from '@pages/home-page/utils';
import { knowledgeAttributes } from '@store/atoms/knowledge-attributes';
import { workspace } from '@store/atoms/workspace';

import AddEditModalLayout from './AddEditModalLayout';

interface AddEditAttributeModalProps {
  isOpen: boolean;
  isEdit?: boolean;
  assetType: AssetType;
  header?: string;
  onCloseClick: () => void;
  defaultValues?: DataItem;
}

const AddEditAttributeModal: FC<AddEditAttributeModalProps> = ({
  isOpen,
  isEdit,
  onCloseClick,
  defaultValues,
}) => {
  const currentWorkspace = useRecoilValue(workspace);
  const knowledgeAttributeList = useRecoilValue(knowledgeAttributes);

  const [selectedOption, setSelectedOption] = useState<AttributeType | null>(
    null,
  );
  const attributeTypes = useMemo(
    () => convertKnowledgeOptionsToArray(knowledgeAttributeList),
    [knowledgeAttributeList],
  );

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<AddAttributeData>({
    mode: 'onChange',
  });

  useEffect(() => {
    const defaultType =
      attributeTypes.find(attr => {
        return attr.label.toLowerCase() === defaultValues?.name?.toLowerCase();
      }) || null;
    setSelectedOption(defaultType);
    setValue('details', defaultValues?.details as string);
  }, [defaultValues, attributeTypes, setValue]);

  const { createBrandAsset, loadingCreateBrandAsset } = useBrandLibrary();
  const { mutateAsync: updateBrandAsset, isLoading } = useUpdateBrandAsset();

  const detailsData = watch('details');

  const onOptionSelect = (option: any) => {
    setSelectedOption(option);
    setValue('type', option);
  };

  const onFormSubmit = async () => {
    if (isEdit) {
      await updateBrandAsset({
        assetId: defaultValues?.id as number,
        metadata: {
          type: selectedOption?.id,
          value: detailsData,
        },
      });
    } else {
      await createBrandAsset({
        clientId: currentWorkspace.id,
        assetType: AssetType.KNOWLEDGE,
        metadata: {
          type: selectedOption?.id,
          value: detailsData,
        },
      });
    }
    onCloseClick();
  };

  const hasChanges =
    selectedOption?.id !== defaultValues?.knowledgeCategory ||
    detailsData !== defaultValues?.details;

  const isSaveDisable =
    isSubmitting ||
    isNil(selectedOption) ||
    loadingCreateBrandAsset ||
    !detailsData?.trim() ||
    !selectedOption ||
    detailsData?.length > MAX_ATTRIBUTE_DETAILS_CHARACTER_LENGTH ||
    (!hasChanges && isEdit);

  return (
    <AddEditModalLayout
      isOpen={isOpen}
      header={`${isEdit ? 'Edit' : 'Add'} attribute`}
      onClose={onCloseClick}
    >
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="pb-8 px-8">
          <div className="flex flex-col mb-8">
            <label className="text-xs font-semibold mt-2 mb-2">Type</label>
            <Select
              options={attributeTypes as AttributeType[]}
              selectedOption={selectedOption as AttributeType}
              onOptionSelect={onOptionSelect}
              placeHolder="Select attribute type"
              PopperRefComponent={SelectTypeRefComponent}
              classOverride="min-w-[200px] max-h-[215px]"
            />
          </div>
          <div>
            <div
              className={`text-xs font-semibold mt-2 mb-2 ${errors?.details ? 'text-red-3' : 'text-gray-18'}`}
            >
              Details
            </div>
            <TextArea
              placeholder="Enter description"
              rows={8}
              isResizable={true}
              classOverride={`border-gray-4 focus:bg-lightest focus:border-primary ${errors?.details ? '!border-red-3 !bg-red-4' : ''}`}
              {...register('details', {
                required: true,
                validate: value => {
                  return value.length <= MAX_ATTRIBUTE_DETAILS_CHARACTER_LENGTH;
                },
              })}
              name="details"
            ></TextArea>

            <div
              className={`text-xs text-gray-13 mt-2 mb-6 ${errors?.details ? 'text-red-3' : ''}`}
            >
              {detailsData ? detailsData?.trim().length : 0} /
              {MAX_ATTRIBUTE_DETAILS_CHARACTER_LENGTH} characters
            </div>
          </div>
          <Button
            label="Save"
            type="submit"
            isLoading={isLoading || loadingCreateBrandAsset}
            isDisabled={isSaveDisable}
            classOverride="font-bold mt-[7px]"
            variant="contained"
          />
        </div>
      </form>
    </AddEditModalLayout>
  );
};

export default AddEditAttributeModal;
