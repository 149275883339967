import { FC, useMemo, useState } from 'react';

import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';

import { Document } from '@assets/icons';
import { Modal } from '@components/index';
import { RetrievedDocument } from '@hooks/types';
import { PreviewType } from '@pages/home-page/enums';

interface ChunkPreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  documentList: RetrievedDocument[];
}

const ChunkPreviewModal: FC<ChunkPreviewModalProps> = ({
  isOpen,
  onClose,
  documentList,
}) => {
  const [selectedDocumentId, setSelectedDocumentId] = useState(
    documentList[0]?.id,
  );
  const [activeTab, setActiveTab] = useState(PreviewType.DOCUMENT);

  const fileNames = documentList.map((document: RetrievedDocument) => ({
    id: document.id,
    file_name: document.file_name,
    image_url: document.image_url,
  }));

  const { fileName, pageNumber, pageContent, imageUrl, externalAssetId } =
    useMemo(() => {
      const selectedDocument = documentList.find(
        document => document.id === selectedDocumentId,
      );

      return {
        fileName: selectedDocument?.file_name || '',
        pageNumber: selectedDocument?.page_number || null,
        pageContent: selectedDocument?.page_content || null,
        imageUrl: selectedDocument?.image_url || '',
        externalAssetId: selectedDocument?.external_asset_id || '',
      };
    }, [selectedDocumentId, documentList]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-row w-[75vw] h-[80vh]">
        <div className="flex flex-col border-r mb-2 w-[35%] overflow-auto">
          <div className="flex flex-row border-b pt-6 pb-[22px] items-center text-sm font-semibold">
            <span className="flex px-4 items-center mt-1 gap-2">
              <Document />
              Source document
            </span>
          </div>
          <div className="px-2 my-2">
            {fileNames.map(fileData => {
              const selectedFileClassOverride =
                fileData.id === selectedDocumentId
                  ? 'bg-lighter rounded-md'
                  : '';

              return (
                <div
                  key={fileData.id}
                  className={twMerge(
                    classNames(
                      'text-sm cursor-pointer py-3 px-2 break-words',
                      selectedFileClassOverride,
                    ),
                  )}
                  onClick={() => setSelectedDocumentId(fileData.id)}
                >
                  {fileData.file_name}
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex flex-row justify-between border-b px-4 pt-6 pb-3 ">
            <div className="flex flex-row">
              <div className="text-md font-semibold">{fileName}</div>
              {externalAssetId && (
                <div className="flex items-center text-sm text-blue-2 border-blue-3 bg-blue-4 rounded-xl p-2 mt-1 font-semibold w-fit max-w-[200px] overflow-hidden">
                  <span className="w-full truncate mt-0.5">
                    {`TAB ID: ${externalAssetId}`}
                  </span>
                </div>
              )}
            </div>
            <div className="text-sm border p-1 rounded mb-1 mr-10">{`Page no. ${pageNumber}`}</div>
          </div>
          <div className="flex items-center justify-center m-3">
            <div className="flex border border-gray-200 rounded-2xl overflow-hidden">
              <span
                onClick={() => setActiveTab(PreviewType.DOCUMENT)}
                className={`py-1 !w-32 cursor-pointer text-center ${
                  activeTab === PreviewType.DOCUMENT
                    ? 'bg-lighter border-lighter text-primary'
                    : 'bg-white'
                }`}
              >
                Document
              </span>
              <span
                onClick={() => setActiveTab(PreviewType.TEXT)}
                className={`py-1 !w-32 cursor-pointer text-center ${
                  activeTab === PreviewType.TEXT
                    ? 'bg-lighter border-lighter text-primary'
                    : 'bg-white'
                }`}
              >
                Text
              </span>
            </div>
          </div>
          <div className="p-4 text-sm overflow-auto">
            {activeTab === PreviewType.TEXT ? (
              pageContent?.split('\n').map((line, index) => (
                <span key={index}>
                  {line}
                  <br />
                </span>
              ))
            ) : (
              <img src={imageUrl} alt="Document image" />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChunkPreviewModal;
