import { FC, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import AddEditModalLayout from './AddEditModalLayout';
import CustomColorPicker from './CustomColorPicker';

import { Button, Input } from '@components/index';
import { InputTypes } from '@enums/input';
import { AssetType } from '@pages/home-page/enums';
import { AddColorData, DataItem, RGBAColorType } from '@pages/home-page/types';
import {
  DEFAULT_HEX_COLOR_FOR_PICKER,
  DEFAULT_RGBA_COLOR,
} from '@pages/home-page/constants';
import { Properties } from '@assets/icons';
import { useBrandLibrary } from '@pages/home-page/hooks/use-brand-library';
import { calculateRgbaFormat, isRgbaInvalid } from '@utils/color';
import { useUpdateBrandAsset } from '@pages/home-page/hooks/use-update-brand-asset';
import { VALIDATE_HEX_REGEX } from '@lib/regex';
import { useRecoilValue } from 'recoil';
import { workspace } from '@store/atoms/workspace';

interface AddEditColorAttributeProps {
  isOpen: boolean;
  isEdit?: boolean;
  assetType: AssetType;
  onCloseClick: () => void;
  defaultValues?: DataItem;
}

const AddEditColorAttribute: FC<AddEditColorAttributeProps> = ({
  isOpen,
  onCloseClick,
  isEdit,
  defaultValues,
}) => {
  const currentWorkspace = useRecoilValue(workspace);

  const [color, setColor] = useState({
    hex: DEFAULT_HEX_COLOR_FOR_PICKER,
    rgba: DEFAULT_RGBA_COLOR,
  });

  useEffect(() => {
    if (defaultValues?.details) {
      setColor({
        hex: defaultValues.details,
        rgba: calculateRgbaFormat(defaultValues.details) as RGBAColorType,
      });
    }
    setValue('name', defaultValues?.name as string);
  }, [defaultValues]);

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<AddColorData>({
    mode: 'onChange',
  });

  const name = watch('name');

  const { createBrandAsset, loadingCreateBrandAsset } = useBrandLibrary();
  const { mutateAsync: updateBrandAsset, isLoading: isUpdatingBrandAsset } =
    useUpdateBrandAsset();

  const onFormSubmit = async () => {
    if (isEdit) {
      await updateBrandAsset({
        assetId: defaultValues?.id as number,
        fileName: name,
        metadata: {
          hex: color.hex,
          rgba: Object.values({
            ...color.rgba,
            a: Math.round(color.rgba.a),
          }) as number[],
        },
      });
    } else {
      await createBrandAsset({
        clientId: currentWorkspace.id,
        assetType: AssetType.COLORS,
        fileName: name,
        metadata: {
          hex: color.hex,
          rgba: Object.values(color.rgba),
        },
      });
    }
    onCloseClick();
  };

  const isSaveDisable =
    isSubmitting ||
    !VALIDATE_HEX_REGEX.test(color.hex) ||
    isUpdatingBrandAsset ||
    loadingCreateBrandAsset ||
    isRgbaInvalid(color.rgba);

  return (
    <AddEditModalLayout
      isOpen={isOpen}
      header={`${isEdit ? 'Edit' : 'Add'} color`}
      onClose={onCloseClick}
    >
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="pb-8 px-8">
          <div className="flex flex-col mb-8">
            <label className="text-xs font-semibold mt-2 mb-2">Name</label>
            <Input
              placeholder="Name"
              type={InputTypes.TEXT}
              customInputClass="focus:border-primary focus:bg-lightest"
              error={errors.name ? errors.name.message : ''}
              {...register('name', {
                required: false,
              })}
            />
          </div>
          <div className="mt-6">
            <div className="border border-gray-4 rounded-lg">
              <div className="flex p-4 border-b border-gray-4">
                <Properties className="mr-2" />
                Properties
              </div>
              <CustomColorPicker
                color={color.rgba}
                setColor={setColor}
                hexColor={color.hex}
              />
            </div>
          </div>
          <Button
            label="Save"
            type="submit"
            classOverride="font-bold mt-8"
            isLoading={isUpdatingBrandAsset || loadingCreateBrandAsset}
            isDisabled={isSaveDisable}
            variant="contained"
          />
        </div>
      </form>
    </AddEditModalLayout>
  );
};

export default AddEditColorAttribute;
