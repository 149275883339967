import { FC, useState } from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { AngleDown, AngleUp, EllipsisGrid } from '@assets/icons';
import { AssetType } from '@pages/home-page/enums';
import { DataItem } from '@pages/home-page/types';

import AttributeRow from './AttributeRow';

interface DraggableAttributeRowProps {
  item: DataItem | DataItem[];
  handleDelete: (id: number, assetType: AssetType) => void;
  handleEdit?: (item: DataItem, assetType: AssetType) => void;
  isDraggable?: boolean;
  isGrouped?: boolean;
  workspaceId: number;
}

const DraggableAttributeRow: FC<DraggableAttributeRowProps> = ({
  item,
  handleDelete,
  handleEdit,
  isDraggable = true,
  isGrouped = false,
  workspaceId,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const ExpandIcon = isExpanded ? AngleUp : AngleDown;
  const items = Array.isArray(item) ? item : [item];
  const firstItem = items[0];

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: firstItem.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <tr
      ref={isDraggable ? setNodeRef : undefined}
      style={isDraggable ? style : undefined}
      {...(isDraggable ? attributes : {})}
      {...(isDraggable ? listeners : {})}
      className={isDraggable ? 'cursor-pointer bg-white' : ''}
    >
      <td className="px-2" colSpan={4}>
        {!isGrouped ? (
          <AttributeRow
            item={firstItem}
            isDraggable={isDraggable}
            isGrouped={false}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            workspaceId={workspaceId}
          />
        ) : (
          <>
            <div className="flex justify-between px-2 my-2">
              <div className="text-sm font-semibold">Global Attributes</div>
              {items?.length > 1 && (
                <div
                  className=" flex text-sm font-semibold border rounded-2xl px-2 py-1 cursor-pointer"
                  onPointerDown={e => {
                    e.stopPropagation();
                    setIsExpanded(prev => !prev);
                  }}
                >
                  {isExpanded ? 'Show less' : 'Show more'}
                  <ExpandIcon className="ml-2" />
                </div>
              )}
            </div>
            <div
              className={`flex bg-lightest py-2 rounded mb-2 ${!isExpanded ? 'shadow-xl' : ''}`}
            >
              <div className="flex flex-col justify-center">
                {isDraggable && (
                  <EllipsisGrid className="h-4 w-4 flex-shrink-0 ml-2" />
                )}
              </div>
              <div className="flex-1 px-2 py-1">
                {isExpanded ? (
                  items.map(nestedItem => (
                    <AttributeRow
                      key={nestedItem.id}
                      item={nestedItem}
                      isGrouped={true}
                      isDraggable={false}
                      handleDelete={handleDelete}
                      handleEdit={handleEdit}
                      workspaceId={workspaceId}
                    />
                  ))
                ) : (
                  <AttributeRow
                    item={firstItem}
                    isDraggable={false}
                    isGrouped={true}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                    workspaceId={workspaceId}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </td>
    </tr>
  );
};

export default DraggableAttributeRow;
