import { ChangeEvent, FC, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { Import } from '@assets/icons';
import {
  Button,
  Input,
  LockedButton,
  PermissionAccess,
  Spinner,
} from '@components/index';
import { UserPermissions } from '@enums/user-permissions';
import { useImportAssetIds } from '@pages/home-page/hooks/use-import-asset-ids';
import { workspace } from '@store/atoms/workspace';

const ID_LIMIT = 25;

interface AssetIdInputProp {
  showImportButton?: boolean;
  classOverride?: string;
  showLabel?: boolean;
}

const AssetIdInput: FC<AssetIdInputProp> = ({
  showImportButton = false,
  classOverride = '',
  showLabel = true,
}) => {
  const currentWorkspace = useRecoilValue(workspace);
  const [assetIds, setAssetIds] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const hasAssets = assetIds?.length > 0;

  const { mutateAsync: importIds, isLoading } = useImportAssetIds();

  const handleImport = () => {
    if (assetIds.length) {
      const distinctIds = assetIds
        .replaceAll(' ', '')
        .split(',')
        .reduce((list: string[], current: string) => {
          if (!list.includes(current)) {
            list.push(current.toLowerCase().trim());
          }

          return list;
        }, []);

      if (distinctIds.length > ID_LIMIT) {
        setErrorMessage(`You've entered over ${ID_LIMIT} IDs`);
      } else {
        setErrorMessage('');
        importIds({
          assetIds: distinctIds,
          clientId: currentWorkspace.id,
          clientUuid: currentWorkspace.uuid,
        });
        setAssetIds('');
      }
    } else {
      setErrorMessage('Asset id is required');
    }
  };

  const handleOnchange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    setAssetIds(e.target.value);
  };

  return (
    <div className="w-full">
      <div className="flex items-end justify-center w-full gap-4">
        <PermissionAccess
          permission={UserPermissions.MANAGE_BRAND_LIBRARY}
          AllowedContent={
            <>
              <Input
                containerClass={`w-[80%] ${classOverride}`}
                value={assetIds}
                placeholder="Eg: 12345, 23456, etc..."
                onChange={handleOnchange}
                showErrorMessage={false}
                error={errorMessage}
                label={showLabel ? "Upload from 'The Asset Bank'" : ''}
              />
              {!showImportButton && (
                <div
                  className={`flex items-center justify-center rounded size-10 ${hasAssets ? 'bg-primary cursor-pointer' : 'bg-gray-2'}`}
                  onClick={isLoading ? () => {} : handleImport}
                >
                  {isLoading ? (
                    <Spinner color="bg-gray-1" />
                  ) : (
                    <Import
                      className={`${hasAssets ? 'text-white' : 'text-gray-14'}`}
                    />
                  )}
                </div>
              )}
            </>
          }
          DeniedContent={
            <>
              <Input
                containerClass={`w-[80%] ${classOverride}`}
                placeholder="Eg: 12345, 23456, etc..."
                label={showLabel ? "Upload from 'The Asset Bank'" : ''}
                disabled
              />
              <LockedButton buttonClassname="w-fit" />
            </>
          }
        />
      </div>
      {showImportButton && (
        <div className="mt-4">
          <Button
            label="Import"
            variant="contained"
            isLoading={isLoading}
            isDisabled={!!errorMessage || assetIds.length === 0}
            onClick={handleImport}
            classOverride="!text-sm"
          />
        </div>
      )}
    </div>
  );
};
export default AssetIdInput;
