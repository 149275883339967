import { FC, useState } from 'react';

import { Document, EllipsisGrid, Locked, Unlocked } from '@assets/icons';
import { MoreOptions } from '@components/index';
import { AssetType } from '@pages/home-page/enums';
import { useLockAction } from '@pages/home-page/hooks/use-lock-action';
import { DataItem } from '@pages/home-page/types';

interface AttributeRowProps {
  item: DataItem;
  workspaceId: number;
  isDraggable?: boolean;
  isGrouped?: boolean;
  handleDelete: (id: number, assetType: AssetType) => void;
  handleEdit?: (item: DataItem, assetType: AssetType) => void;
}

const AttributeRow: FC<AttributeRowProps> = ({
  item,
  isDraggable = true,
  workspaceId,
  handleDelete,
  handleEdit,
  isGrouped = false,
}) => {
  const [lockState, setLockState] = useState(item.isLocked);

  const { mutate: toggleLock, isLoading } = useLockAction(
    Boolean(item.isLocked),
  );

  const isInherited = item.parentClientId !== workspaceId;
  const showMoreActions = !item.isLocked || !isInherited;

  const getLockIcon = () => {
    return item.isLocked ? (
      <Locked className={`${isInherited ? 'text-gray-14' : 'text-primary'}`} />
    ) : (
      <Unlocked
        className={`${isInherited ? 'text-gray-14' : 'text-primary'}`}
      />
    );
  };

  const onLockError = () => {
    setLockState(item.isLocked);
  };

  const handleToggle = (id: number) => {
    setLockState(prev => !prev);
    toggleLock({
      attributeId: id,
      onError: onLockError,
    });
  };

  return (
    <div className="w-full flex flex-1 flex-row rounded border border-gray-2 p-2 items-start relative bg-white mb-2 min-h-20">
      <div className="w-[45%] flex flex-row gap-4 items-center">
        {isDraggable && <EllipsisGrid className="h-4 w-4 flex-shrink-0" />}
        <div
          className="text-sm flex-grow overflow-hidden text-ellipsis whitespace-normal pr-10"
          style={{ wordBreak: 'break-word' }}
        >
          {item.details}
        </div>
      </div>
      <div
        className={`w-[20%] flex-grow overflow-hidden text-ellipsis whitespace-normal text-sm ${!isGrouped ? 'pl-4' : 'pl-1'}`}
        style={{ wordBreak: 'break-word' }}
      >
        {item?.parentClientName}
        {item.sourceDocument?.id && (
          <div
            className="flex text-[13px] text-blue-2 border-blue-3 items-start bg-blue-4 rounded-xl p-2 mt-1 font-semibold w-fit max-w-[200px] text-ellipsis whitespace-normal"
            style={{ wordBreak: 'break-word' }}
          >
            <Document className="mr-2 mt-1" />
            <span className="w-full mt-0.5">{item.sourceDocument?.label}</span>
          </div>
        )}
      </div>
      <div
        className={`w-[15%] text-sm flex-grow overflow-hidden text-ellipsis whitespace-normal ${!isGrouped ? 'pl-5' : 'pl-3'}`}
        style={{ wordBreak: 'break-word' }}
      >
        {item.createdBy}
      </div>
      <div className="w-[15%] items-start text-sm pl-10">
        {item.createdAt || '-'}
      </div>
      <div
        className={`flex justify-end w-[5%] ${!isGrouped ? 'pr-2' : 'pr-0'} `}
        onPointerDown={e => e.stopPropagation()}
      >
        {showMoreActions && (
          <MoreOptions
            enabled={lockState}
            classOverride="!items-start"
            isLoading={isLoading}
            onToggle={() => handleToggle(item.id)}
            showLock={!isInherited && !!item.parentClientId}
            {...(!item.isLocked && {
              onClickDelete: () => handleDelete(item.id, AssetType.KNOWLEDGE),
            })}
            {...(!isInherited &&
              !item.isLocked && {
                onClickEdit: () => handleEdit?.(item, AssetType.KNOWLEDGE),
              })}
          />
        )}
      </div>
      <span
        className={`absolute cursor-default bottom-0 right-0 p-2 rounded-tl-lg rounded-br-md ${isInherited ? 'bg-gray-2' : 'bg-lighter'}`}
      >
        {getLockIcon()}
      </span>
    </div>
  );
};

export default AttributeRow;
