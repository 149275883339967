import { each, isEmpty, isNil, isObject, isString, map } from 'lodash';

import { KnowledgeAttribute } from '@store/atoms/knowledge-attributes';

import { FilterData } from './components/brand-library/asset-table/AttributeFilter';
import { AttributeType, DataItem, GroupedKnowledgeItems } from './types';

export const doesValueExist = (value: string, searchTerm: string) => {
  return value.toLowerCase().includes(searchTerm.toLowerCase());
};

export const searchObject = (obj: DataItem, searchTerm: string) => {
  for (const value of Object.values(obj)) {
    // Searching in nested objects
    if (isObject(value) && !isNil(value)) {
      if (searchObject(value as DataItem, searchTerm)) {
        return true;
      }
    } else if (isString(value) && doesValueExist(value, searchTerm)) {
      return true;
    }
  }

  return false;
};

export const filterResults = (searchTerm: string, assets: DataItem[]) => {
  if (searchTerm) {
    const filteredResults = assets?.filter((item: DataItem) =>
      searchObject(item, searchTerm),
    );

    return filteredResults;
  }

  return assets;
};

export const getTruncatedFontName = (fontName: string) => {
  return fontName ? fontName.match(/(.+?)(\.[^.]*$|$)/)?.[1] : '';
};

export const loadCustomFonts = async (customFonts: any[]) => {
  // remove extension from font file to create font family
  const customFontFaces = customFonts?.map(font => {
    const fontName = font?.name || font?.fileName || '';

    return {
      fontAsset: font,
      font: new FontFace(
        getTruncatedFontName(fontName) || '',
        `url(${font.thumbnailUrl})`,
      ),
    };
  });

  const loadedFontsPromise = Promise.all(
    map(customFontFaces, cff => {
      return cff.font.load().catch(error => {
        console.warn(`Error loading font: ${cff.font.family}`);

        return { error, font: cff.fontAsset };
      });
    }),
  );

  const loadedFonts = await loadedFontsPromise;
  const fontErrors: any = [];
  if (!isEmpty(loadedFonts)) {
    each(loadedFonts as any[], res => {
      if (res.error) {
        fontErrors.push(res.font);
      } else {
        (document as any).fonts.add(res);
      }
    });
  }
  await document.fonts.ready;

  return fontErrors;
};

export const convertKnowledgeOptionsToArray = (
  knowledgeOptions: KnowledgeAttribute[],
): AttributeType[] => {
  return knowledgeOptions.map(item => ({
    id: item.attributeName,
    label: item.displayName,
  }));
};

// Helper function to group document items
export const groupDocumentItems = (
  items: DataItem[],
  workspaceId: number,
): (DataItem | DataItem[])[] => {
  const groupedItems: (DataItem | DataItem[])[] = [];
  let tempGroup: DataItem[] = [];

  for (const item of items) {
    if (item.parentClientId === workspaceId) {
      if (tempGroup.length) {
        groupedItems.push(tempGroup);
        tempGroup = [];
      }
      groupedItems.push(item);
    } else {
      tempGroup.push(item);
    }
  }

  if (tempGroup.length) groupedItems.push(tempGroup);

  return groupedItems;
};

export const flattenItems = (items: (DataItem | DataItem[])[]) =>
  items.flatMap(item => (Array.isArray(item) ? item : [item]));

export const filterCombinedDocumentItems = (
  combinedItems: (DataItem | DataItem[])[],
  dataItems: DataItem[],
): (DataItem | DataItem[])[] => {
  const dataItemIds = new Set(dataItems.map(item => item.id));
  const filteredItems: (DataItem | DataItem[])[] = [];

  combinedItems.forEach(item => {
    if (Array.isArray(item)) {
      const filteredGroup = item.filter(groupedItem =>
        dataItemIds.has(groupedItem.id),
      );
      if (filteredGroup.length > 0) {
        filteredItems.push(filteredGroup);
      }
    } else if (dataItemIds.has(item.id)) {
      filteredItems.push(item);
    }
  });

  return filteredItems;
};

const filterByDocuments = (
  dataItems: DataItem[],
  selectedDocuments: FilterData[],
) =>
  selectedDocuments.length > 0
    ? dataItems.filter(dataItem =>
        selectedDocuments.some(doc => doc.id === dataItem?.sourceDocument?.id),
      )
    : dataItems;

export const getFilteredKnowledgeItems = (
  knowledgeItems: GroupedKnowledgeItems,
  {
    category: selectedCategories,
    document: selectedDocuments,
  }: {
    category: FilterData[];
    document: FilterData[];
  },
): GroupedKnowledgeItems | null => {
  if (selectedCategories.length === 0 && selectedDocuments.length === 0) {
    return null;
  }

  const filteredItems = Object.keys(knowledgeItems || {}).reduce(
    (result, category) => {
      const isCategorySelected = selectedCategories.some(
        option => option.label === category,
      );

      const dataItems = knowledgeItems?.[category] || [];
      let matchingDataItems: DataItem[] = [];

      if (selectedCategories.length && selectedDocuments.length) {
        // Both category and document filters are present
        if (isCategorySelected) {
          matchingDataItems = filterByDocuments(dataItems, selectedDocuments);
          if (matchingDataItems.length) {
            result[category] = matchingDataItems;
          }
        }
      } else if (selectedCategories.length && isCategorySelected) {
        // Only category filter is present
        matchingDataItems = dataItems;
        result[category] = matchingDataItems;
      } else if (selectedDocuments.length) {
        // Only document filter is present
        matchingDataItems = filterByDocuments(dataItems, selectedDocuments);
        if (matchingDataItems.length) {
          result[category] = matchingDataItems;
        }
      }

      return result;
    },
    {} as GroupedKnowledgeItems,
  );

  return filteredItems;
};

export const isValidImage = (url: string) => {
  const previewRestrictedExtensions = ['tiff', 'tif'];
  const extension = url.split('.').pop()?.toLowerCase();

  return extension && !previewRestrictedExtensions.includes(extension);
};
