import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { Actions, AssetType } from '@pages/home-page/enums';
import {
  AssetToDelete,
  CollapsibleItemsState,
  DataItem,
  GroupedKnowledgeItems,
  SelectedAsset,
} from '@pages/home-page/types';
import { getFilteredKnowledgeItems } from '@pages/home-page/utils';
import {
  KnowledgeAttribute,
  knowledgeAttributes,
} from '@store/atoms/knowledge-attributes';

import AssetDataItems from './AssetDataItems';
import { FilterData } from './AttributeFilter';
import { CollapsibleAssetWrapper } from './CollapsibleAssetWrapper';
import AddEditModals from '../add-edit-modals/AddEditModals';
import DeleteAssetModal from '../delete-asset-modal/DeleteAssetModal';

type CollapsibleAssetItemProps = {
  assetItem: {
    id: AssetType;
    header: string;
    tooltipMessage: string;
    dataItems: DataItem[];
    imageClassName?: string;
  };
  collapsibleItemsState: CollapsibleItemsState;
  savedFilter: SavedFilter;
  setSavedFilters: Dispatch<SetStateAction<SavedFilter>>;
  isLoading: boolean;
  isFiltered?: boolean;
};

export interface SavedFilter {
  category: FilterData[];
  document: FilterData[];
}

const CollapsibleAssetItem = ({
  assetItem,
  collapsibleItemsState,
  isLoading,
  savedFilter,
  setSavedFilters,
  isFiltered = false,
}: CollapsibleAssetItemProps) => {
  const knowledgeAttributeList = useRecoilValue(knowledgeAttributes);

  const [isCollapsed, setIsCollapsed] = useState(
    collapsibleItemsState.isCollapseAll,
  );

  const [assetToDelete, setAssetToDelete] = useState<AssetToDelete>({
    id: null,
    assetType: null,
  });
  const [selectedAction, setSelectedAction] = useState<SelectedAsset | null>(
    null,
  );

  const [filteredKnowledgeItems, setFilteredKnowledgeItems] =
    useState<GroupedKnowledgeItems | null>(null);

  useEffect(() => {
    setIsCollapsed(collapsibleItemsState.isCollapseAll);
  }, [collapsibleItemsState]);

  const handleDelete = (id: number, assetType: AssetType) => {
    setAssetToDelete({
      id: id,
      assetType: assetType,
    });
  };

  const handleEdit = (item: DataItem, assetType: AssetType) => {
    setSelectedAction({ action: Actions.EDIT, assetType, asset: item });
  };

  const onDeleteModalClose = () => {
    setAssetToDelete({
      id: null,
      assetType: null,
    });
  };

  const getGroupedKnowledgeItems = useMemo(() => {
    if (assetItem.id !== AssetType.KNOWLEDGE) return null;

    const groupedItems = (dataItems: DataItem[]) => {
      const knowledgeCategory: { [key: string]: DataItem[] } =
        knowledgeAttributeList.reduce(
          (
            acc: { [key: string]: DataItem[] },
            knowledgeAttribute: KnowledgeAttribute,
          ) => {
            acc[knowledgeAttribute.displayName] = [];

            return acc;
          },
          {},
        );

      // Populate the categories with corresponding dataItems
      dataItems.forEach(item => {
        knowledgeCategory[item.name].push(item);
      });

      return knowledgeCategory;
    };

    const filteredItems = getFilteredKnowledgeItems(
      groupedItems(assetItem.dataItems) as unknown as GroupedKnowledgeItems,
      savedFilter,
    );
    setFilteredKnowledgeItems(filteredItems);

    return groupedItems;
  }, [assetItem.id, assetItem.dataItems, savedFilter]);

  return (
    <>
      <CollapsibleAssetWrapper
        savedFilter={savedFilter}
        setSavedFilters={setSavedFilters}
        setFilteredKnowledgeItems={setFilteredKnowledgeItems}
        assetType={assetItem?.id}
        header={assetItem.header}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        setSelectedAction={setSelectedAction}
        {...(assetItem.id === AssetType.KNOWLEDGE && {
          knowledgeDataItems: getGroupedKnowledgeItems?.(assetItem.dataItems),
        })}
      />
      {!isCollapsed && (
        <AssetDataItems
          dataItems={assetItem?.dataItems}
          isLoading={isLoading}
          imageClassName={assetItem?.imageClassName || ''}
          assetType={assetItem?.header}
          handleDelete={(id, assetType) => handleDelete(id, assetType)}
          handleEdit={(item, assetType) => handleEdit(item, assetType)}
          {...(assetItem.id === AssetType.KNOWLEDGE && {
            knowledgeDataItems:
              filteredKnowledgeItems ??
              getGroupedKnowledgeItems?.(assetItem.dataItems),
          })}
          isFilteredKnowledgeDataItems={
            isFiltered || (filteredKnowledgeItems ? true : false)
          }
        />
      )}
      {assetToDelete.id && assetToDelete.assetType && (
        <DeleteAssetModal
          assetToDelete={assetToDelete}
          onCloseModal={onDeleteModalClose}
        />
      )}
      {selectedAction && (
        <AddEditModals
          selectedAsset={selectedAction}
          setSelectedAction={setSelectedAction}
          isEdit={selectedAction?.action === Actions.EDIT}
        />
      )}
    </>
  );
};

export default CollapsibleAssetItem;
